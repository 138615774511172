import React from 'react'
import './Buy.scss'
import $ from 'jquery'
import 'select2'
import gsap from 'gsap'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import FullNameInput from '../shared/FullNameInput/FullNameInput'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import paymentService from '../../services/PaymentService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import scheduleService from '../../services/ScheduleService'
import { MemberType } from '../../services/MemberService'

class Buy extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;
        this.userSwitcher = null;
        this.userSwitch = null;
        this.nameInput = null;
        this.passwordInput = null;
        this.confirmInput = null;

        // State
        this.state = {
            useFullName: true,
        }

        // This Binding
        this.clickSwitch = this.clickSwitch.bind(this);
        this.ignoreFullName = this.ignoreFullName.bind(this);
        this.fillSandboxForm = this.fillSandboxForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
        this.setupSwitcher();
    }

    componentDidUpdate() {
        this.setupSelects();
    }

    // Helpers
    setupSwitcher() {
        if (this.userSwitcher) {
            const userSelections = this.userSwitcher.querySelectorAll(".a4-buy-form-selection");
            
            if (paymentService.memberType === "register") {
                userSelections[0].classList.add("-active");
                this.userSwitch.style.width = userSelections[0].offsetWidth + 5 + "px";
                this.userSwitch.style.left = userSelections[0].offsetLeft + 20 + "px";
                this.toggleAccountInputs("register");
            }
            else {
                userSelections[1].classList.add("-active");
                this.userSwitch.style.width = userSelections[1].offsetWidth + 5 + "px";
                this.userSwitch.style.left = userSelections[1].offsetLeft + 20 + "px";
                this.toggleAccountInputs("login");
            }
        }
    }

    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-buy-form-btn-group-user select, .a4-buy-form-btn-group-payment select').on("change", (e, shouldIgnore) => {
            if (!shouldIgnore) {
                const selectedSwitch = $(this.userSwitcher).find(".a4-buy-form-selection").filter(`[data-value="${e.currentTarget.value}"]`)[0];

                if (selectedSwitch) {
                    this.setSwitchActive(selectedSwitch);
                    this.toggleAccountInputs(e.currentTarget.value);
                    paymentService.memberType = e.currentTarget.value;
                }
            }
        });
    }

    setSwitchActive(selection) {
        formService.hideAllErrors(this.form);
        
        $(selection).siblings().removeClass("-active");
        selection.classList.add("-active");

        gsap.to(this.userSwitch, {
            width: selection.offsetWidth,
            left: selection.offsetLeft + 20,
            duration: 0.2,
        });
    }
    
    toggleAccountInputs(value) {
        if (value === "login") {
            this.nameInput.hideInputs();

            $(this.passwordInput).css("margin-bottom", 0)
            this.confirmInput.classList.add("-disabled");
            $(this.confirmInput).hide(200);

        } else {
            this.nameInput.showInputs();

            $(this.confirmInput).show(0, () => {
                $(this.passwordInput).css("margin-bottom", 34)
                this.confirmInput.classList.remove("-disabled");
            });
        }
    }

    // Event Handlers
    clickSwitch(e) {
        if (!e.currentTarget.classList.contains("-active")) {
            this.setSwitchActive(e.currentTarget);
            this.toggleAccountInputs(e.currentTarget.dataset.value);

            const select = $(e.currentTarget).parent().find("select");
            select.val(e.currentTarget.dataset.value);
            select.trigger("change", true);

            paymentService.memberType = e.currentTarget.dataset.value;
        }
    }

    fillSandboxForm(e) {
        e.preventDefault();

        if (e.ctrlKey || e.metaKey) {

            const inputs = this.form.querySelectorAll('.a4-input');

            inputs[0].querySelector("input, textarea").value = "Erick Harris"
            inputs[1].querySelector("input, textarea").value = "eh1@harris5.com"
            inputs[2].querySelector("input, textarea").value = "password"
        }
    }

    ignoreFullName() {
        this.setState({ useFullName: false });
    }

    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const email = e.target.email.value.trim();
        const password = e.target.password.value;
        const confirm = e.target.confirm.value;

        if (paymentService.memberType === "register" && password !== confirm) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            formService.hideLoadingButton(this.form);
            return;
        }

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        let memberRequest = {email, password};

        recaptchaService.getToken(paymentService.paymentType)
        .then(token => {
            memberRequest.recaptchaToken = token;

            if (paymentService.memberType === "register") {
                memberRequest.requestType = 1;
                memberRequest.memberType = MemberType.Payment;
                memberRequest.timezoneOffset = -(new Date().getTimezoneOffset() / 60);
                memberRequest.contactId = memberService.getMtcId();

                if (this.state.useFullName) {
                    memberRequest.firstName = e.target.fullName.value.split(" ")[0];
                    memberRequest.lastName = e.target.fullName.value.split(" ")[1];
                }
                else {
                    memberRequest.firstName = e.target.firstName.value;
                    memberRequest.middleName = e.target.middleName.value;
                    memberRequest.lastName = e.target.lastName.value;
                }
    
                memberService.checkEmailInUse(memberRequest)
                .then((isInUse) =>  {
                    if (isInUse) {
                        formService.hideAllErrors(this.form);
                        formService.hideLoadingButton(this.form);
                        formService.showInputError(this.form, "email", "This email address is already taken")
                    }
                    else {
                        memberService.validateMember(memberRequest)
                        .then(json => {
                            if (json.succeeded) {
                                paymentService.member = memberRequest;
                                this.props.history.push("/payment");
                            }
                            else {
                                formService.hideAllErrors(this.form);
                                formService.hideLoadingButton(this.form);
                                formService.showFormError(this.form, json.errors[0]);
                            }
                        });
                    }
                })  
            }
            else if (paymentService.memberType === "login") {
                memberRequest.requestType = 0;
                memberRequest.contactId = memberService.getMtcId();

                memberService.validateMember(memberRequest)
                .then(json => {
                    if (json.succeeded) {
                        paymentService.member = memberRequest;
                        this.props.history.push("/payment");
                    }
                    else {
                        formService.hideAllErrors(this.form);
                        formService.hideLoadingButton(this.form);
                        formService.showFormError(this.form, json.errors[0]);
                    }
                })         
            }
        })
    }

    // Component
    render() {
        // Go Home If No Payment Data
        if (!paymentService.item) {
            this.props.history.push(`/pricing/${bootstrapService.defaultPlanType}`);
            return null;
        }

        const title = paymentService.item ? paymentService.item.title : null;
        const schedule = paymentService.schedule ? `${new Date(paymentService.schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}, ${new Date(paymentService.schedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - ${new Date(paymentService.schedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} (${scheduleService.getTimeZone()})` : null;

        const firstName = paymentService.member ? paymentService.member.firstName : "";
        const middleName = paymentService.member ? paymentService.member.middleName : "";
        const lastName = paymentService.member ? paymentService.member.lastName : "";
        const email = paymentService.member ? paymentService.member.email : "";
        const password = paymentService.member ? paymentService.member.password : "";

        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <div className="a4-layout a4-buy">
                        <section className="a4-buy-container">
                            <div className="a4-buy-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                <header className="a4-buy-title">
                                    <h1>
                                        {title}
                                    </h1>
                                    {
                                        schedule &&
                                        <h3>
                                            {schedule}
                                        </h3>
                                    }    
                                </header>
                                <form className="a4-buy-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                    <div className="a4-buy-form-group">
                                        <div className="a4-buy-form-group-switcher-container -user" ref={div => this.userSwitcher = div}>
                                            <div className="a4-buy-form-btn-group a4-buy-form-btn-group-user">
                                                <div className="a4-select sm_hidden">
                                                    <select defaultValue="register">
                                                        <option value="register">Create New Account</option>
                                                        <option value="login">Login To My Account</option>
                                                    </select>
                                                </div>
                                                <div className="a4-buy-form-selection hidden sm_block" type="button" data-value="register" onClick={this.clickSwitch}>
                                                    Create New Account
                                                </div>
                                                <div className="a4-buy-form-selection hidden sm_block" type="button" data-value="login" onClick={this.clickSwitch}>
                                                    Login To My Account
                                                </div>
                                            </div>
                                            <div className="a4-switcher" ref={div => this.userSwitch = div}></div>
                                        </div>
                                        <FullNameInput ref={div => this.nameInput = div} ignoreFullName={this.ignoreFullName} firstName={firstName} middleName={middleName} lastName={lastName}/>
                                        <div className="a4-input">
                                            <input name="email" required placeholder="E-mail address" defaultValue={email} />
                                            <label htmlFor="email">E-mail address</label>
                                            <span className="a4-input-error">Enter a valid e-mail address</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input" ref={div => this.passwordInput = div}>
                                            <input name="password" placeholder="Password" required type="password" defaultValue={password} />
                                            <label htmlFor="password">Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input" ref={div => this.confirmInput = div}>
                                            <input name="confirm" placeholder="Confirm Password" required type="password" defaultValue={password} />
                                            <label htmlFor="password">Confirm Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="a4-form-error"></p>
                                    <BubbleButton className="a4-form-submit -primary -fixed" type="submit" formNoValidate>
                                        Proceed to Payment
                                    </BubbleButton>
                                    <div className="a4-buy-form-terms" onClick={this.fillSandboxForm}>
                                        Your payment information will be collected on the next page
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default Buy;